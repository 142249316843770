
@import "../../bootstrap/variables.less";

@body-bg:					#003C00;

@text-color:				#fff;
@link-color:				#0f0;
@link-hover-color:			#fff;
@link-hover-decoration:		underline;

@font-family-base:			Verdana, Arial, Helvetica, sans-serif;
@font-size-base:			10px;
@line-height-base:			1;

@font-size-h1:				50px;
@headings-small-color:		#fff;

@thumb-width:				492px;
@thumb-height:				368px;
@thumb-margin-top:			0;
@thumb-margin-right:		0;
@thumb-margin-bottom:		0;
@thumb-margin-left:			0;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #000;
@thumb-hover-border:		@thumb-border-width solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				20px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				18px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	0 0 10px;
@sites-footer-margin:				2px;
@sites-footer-size:					12px;
@sites-footer-color:				#fff;

@parent-alert-padding:		10px;
@parent-alert-margin:		20px auto;
@parent-alert-border:		1px solid #000;
@parent-alert-font-size:	11px;
@parent-alert-color:		#fff;
@parent-alert-bg:			#007900;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/parent_alert.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	text-align: center;
	margin: 0 auto 15px;
	color: #fff;
	font-weight: bold;
	font-size: 17px;
	.header {
		display: inline-block;
		background: rgb(0, 121, 0);
		padding: 1px 20px;
	}
	.title {
		float: left;
		padding-right: 20px;
	}
	.address {
		float: right;
		padding-left: 20px;
	}
	.header-links {
		font-size: 14px;
		font-weight: normal;
		text-align: left;
		padding-right: 100px;
	}
}
#language-switcher {
	position: absolute;
	top: 0;
	right: 10px;
}
#ad-footer {
	img,
	video {
		border: 1px solid @link-color;
	}
}
.outro {
	font-size: 18px;
	margin: 20px auto;
	a {
		color: #fff;
		text-decoration: none;
	}
}
#parent-alert {
	a {
		&, &:hover {
			color: #000;
		}
	}
}
@media(max-width: 480px) {
	h1 {
		font-size: 14px;
		small {
			font-size: 12px;
		}
	}
	.alert {
		width: 100%;
	}
}

@media(max-width: 359px) {
	h1 {
		font-size: 14px;
		small {
			font-size: 12px;
		}
	}
	.alert {
		width: 100%;
	}
}
